<template>
    <div class="header">
        <h1 class='display-2'>kratodo</h1>
        <small>A simple to-do app</small>
    </div>
</template>

<script>
    export default {
        name: 'Header'
    }
</script>